import jwt_decode from "jwt-decode";
// import axios from "axios";
// import errorMessage from "../Messages/error";
// import { jwt as jwtConst } from "../Config/Const";

// let headers = {
//   Accept: "application/json",
//   "Content-Type": "application/json",
//   authorization: ""
// };

export const userId = () => {
  let token = localStorage.getItem("_kyubi_token");
  if (!token) return "";
  return jwt_decode(token).user.id
  // console.log("jwt_decode(token).user.user_type ::: ", jwt_decode(token).user.user_type)
  // if (jwt_decode(token).user.user_type === userType) return true;
}

export const checkAuthUserType = userType => {
  // console.log("userType 13 ",userType)
  let token = localStorage.getItem("_kyubi_token");
  if (!token) return false;
  // console.log("jwt_decode(token).user.user_type ::: ", jwt_decode(token).user.user_type)
  if (jwt_decode(token).user.user_type === userType) return true;
};

export const getUserName = () => {
  let token = localStorage.getItem("_kyubi_token");
  if (!token) return "";
  return jwt_decode(token).user.first_name;
};

export const getUserId = () => {
  let token = localStorage.getItem("_kyubi_token");
  if (!token) return "";
  return jwt_decode(token).user.id;
};

export const getUserEmail = () => {
  let token = localStorage.getItem("_kyubi_token");
  if (!token) return "";
  return jwt_decode(token).user.email;
};