import React from "react";
import { Route, Redirect } from "react-router-dom";
import Login from "./components/Login/Login";
import { checkLogin } from "./Services/Auth/Authenticate";

export const UnProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkLogin() ? <Redirect to="/dashboard" /> : <Component {...props} />
    }
  />
);

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkLogin() ? (
        <React.Fragment>
          <Component {...props} params={props.match} />
        </React.Fragment>
      ) : (
        <Redirect to="/login" component={Login} />
      )
    }
  />
);
