module.exports = {
  jwt: "KYUBI_",
  superAdmin: 0,
  ceo: 1,
  partner: 2,
  user: 3,
  balance: {
    default: 0,
    min: 0,
    max: 99999999,
  },
  env: {
    local: "local",
    dev: "development",
    live: "production",
  },
  extensionLogoProps: {
    fileType: ["image/png", "image/jpg", "image/jpeg"],
    small: {
      size: "16px X 16px",
      height: 16,
    },
    medium: {
      size: "48px X 48px",
      height: 48,
    },
    large: {
      size: "128px X 128px",
      height: 128,
    },
  },
  dashboardAdPosition: [
    { label: "Header Left", value: "header-left" },
    { label: "Header Right", value: "header-right" },
  ],
  dashboardAdTarget: [
    { label: "CEO", value: 1 },
    { label: "Partners", value: 2 },
  ],
  unlimitedText: "Unlimited",
  unlimitedSymbol: "∞",
  ceoBalanceProp: {
    extension: "EXTENSION",
    partner: "PARTNER",
    endUser: "END-USER",
  },
  fileTypesAllowed: {
    jsonFiles: ["application/json"],
  },
  S3URLCommon: "https://tier5-kyubi.s3.amazonaws.com/extensionfiles/",
  stripeZeroDecimal: {
    MGA: true,
    BIF: true,
    CLP: true,
    PYG: true,
    DJF: true,
    RWF: true,
    GNF: true,
    UGX: true,
    VND: true,
    JPY: true,
    VUV: true,
    XAF: true,
    KMF: true,
    XOF: true,
    KRW: true,
    XPF: true,
  },
};
