let { backendUrl: backendUrl } = require("./env");

let superUserPrefix = "superUser/";


// Misc. urls
export const facebook = "//www.facebook.com/tier5development";
// export const facebookJon =  "//www.facebook.com/theceojonvaughn";
export const facebookJon = "//www.jonvaughn.io/fb";
export const messenger = "//www.messenger.com/t/tier5development";
export const tier5Website = "//tier5.us";

export const login = backendUrl + "login";
export const updatePassword = backendUrl + "update-password";

export const generateTokenEndUser =
  backendUrl + "end-user/generate-password-token";
export const generateToken = backendUrl + "generate-password-token";
export const resetPassword = backendUrl + "password-reset/";
export const resetPasswordEndUser = backendUrl + "end-user/password-reset/";

// super user url
export const addCeo = backendUrl + superUserPrefix + "addceo";
// export const addCeoNV =  backendUrl + superUserPrefix + "addceo-nv";
export const getCeo = backendUrl + superUserPrefix + "fetchAllCeo";
export const getAllEndUser =
  backendUrl + superUserPrefix + "fetchAllExtensionUsers";
export const downloadEnduserForSuper =
  backendUrl + superUserPrefix + "enduserdownload";
export const resetEnduserLogin =
  backendUrl + superUserPrefix + "resetEnduserLogin";

export const suspendCeo = backendUrl + superUserPrefix + "suspendceo";
export const unSuspendCeo = backendUrl + superUserPrefix + "un-suspendceo";
export const deleteCeo = backendUrl + superUserPrefix + "deleteCeo";
export const dashboardDetails =
  backendUrl + superUserPrefix + "dashboardDetails";
export const addCSV = backendUrl + superUserPrefix + "addUserFromCsv";
export const getWLExtensionList =
  backendUrl + superUserPrefix + "fetchWhitelabelExtension";
export const manageWLExtension =
  backendUrl + superUserPrefix + "assignWhitelabelExtension";
export const manageCeoForWLExtension =
  backendUrl + superUserPrefix + "assignWhitelabelConfigToCeo";
export const createOrUpdateAds =
  backendUrl + superUserPrefix + "create-dashboard-ads";
export const showDashboardAds =
  backendUrl + superUserPrefix + "show-dashboard-ads";
export const deleteDashboardAds =
  backendUrl + superUserPrefix + "delete-dashboard-ads";
export const toggleDashboardAds =
  backendUrl + superUserPrefix + "toggle-dashboard-ads";
export const toggleOverrideBalance =
  backendUrl + superUserPrefix + "toggle-override-balance";

export const deleteWhitelabelConfigUrl =
  backendUrl + superUserPrefix + "deleteWhitelabelConfiguration";

export const addVersionNumberUrl =
  backendUrl + superUserPrefix + "addVersionNumber";

/**
 * Api endpoint for creating a white label extension from super user side
 */
export const configureWhiteLabelUrl =
  backendUrl + superUserPrefix + "configureWhiteLebel";
export const configureKyubiSettingsUrl =
  backendUrl + superUserPrefix + "configureKyubiSettings";
export const fetchWLConfigDetailsUrl =
  backendUrl + superUserPrefix + "configureWhiteLebel-fetch";
export const fetchWhiteLableConfigUrl =
  backendUrl + superUserPrefix + "fetchWhitelabelExtension";
export const fetchWhiteLableRequestUrl =
  backendUrl + superUserPrefix + "listwhitelabelRequest";
export const actiinOnWhitelabelRequestUrl =
  backendUrl + superUserPrefix + "actionOnWhitelabelRequest";

// CEO url
// export const addPartner =  backendUrl + superUserPrefix + "adduser";
export const addPartner = backendUrl + superUserPrefix + "addpartner";
// export const getPartner =  backendUrl + superUserPrefix + "fetchALLPartner";
export const getPartner = backendUrl + "partner/fetchALLPartner";
//I don't think velow api is being used anywhere
export const getPartnerList = backendUrl + "partner/fetchALLPartner";
export const unSuspendPartner = backendUrl + "ceo/un-suspend-partner";
export const suspendPartner = backendUrl + "ceo/suspend-partner";
export const deletePartner = backendUrl + "delete-partner";
export const getExtensionList = backendUrl + "ceo/fetch-all-extensions";
export const resetEnduserPasswordURL = backendUrl  + "ceo/resetUSerPassword";
export const getManageExtensionList = backendUrl + "ceo/fetch-manage-extensions";
export const getAllExtensionList =
  backendUrl + "ceo/fetch-all-extensions-forCEO";
export const extensionPartnerDetails = backendUrl + "ceo/extension-details";
export const addExtensionToPartner =
  backendUrl + "ceo/add-extension-to-partner";
export const removeExtensionFromPartner = backendUrl + "ceo/remove-extension";
export const updatePlan = backendUrl + "extension/update-plan";
export const getCeoBalance = backendUrl + "ceo/get-ceo-balance";
export const checkForUnlimitedEndUsers = backendUrl + "ceo/check-for-unlimited";
//for downloading endusers
export const downloadEndUserForCeo = backendUrl + "ceo/enduserdownload";
export const downloadWL = backendUrl + "ceo/whitelabel-download";
export const downloaddefaultExt = backendUrl + "/downloads";
export const migrateExtension = backendUrl + "ceo/migrate-to-whitelabel";
export const updateEndUserPlan = backendUrl + "/end-user/update-plan";

// export const getPartnerList =  backendUrl + "getExtensionDetails";
export const getUserList = backendUrl + "getExtensionDetails";
export const getExtensionDetail = backendUrl + "getExtensionDetails";
export const addExtension = backendUrl + "extension/create";
export const deleteExtension = backendUrl + "extension/remove";
export const uploadExt = backendUrl + "ceo/upload-extension";
export const getDownloadExt = backendUrl + "extension/download";
export const manageExtension = backendUrl + "extension/manage-extension";
export const suspendUnsuspendExtURL = backendUrl + "extension/extension-suspention";
export const updateChromeStoreLink =
  backendUrl + "extension/create-or-update-chrome-store-link";
export const getWLExtensionListForCeo =
  backendUrl + "ceo/fetch-all-whitelabel-extensions";
export const createWLExtension =
  backendUrl + "ceo/createorupdate-whitelabel-extension";
export const configureKyubiSettingsCeoUrl =
  backendUrl + "ceo/createorupdate-whitelabel-extension";
export const fetchWLExtensionDetails = backendUrl + "extension/details";
export const toggleAuth2 = backendUrl + "extension/toggle-auth2";

export const fetchWLversions = backendUrl + "ceo/whitelabel-versions";
export const versionUpdateUrl = backendUrl + "ceo/update-version-request";

export const configureBroadcast = backendUrl + "ceo/configure-broadcast";

export const broadcastListURL = backendUrl + "extension/fetch-broadcast-list";
export const broadcastDeleteURL = backendUrl + "extension/delete-broadcast";
export const manageLinksURL = backendUrl + "extension/manage-links";
export const createOrEditManageLinksURL = backendUrl + "extension/manage-links/create-or-update-link";
export const deleteManageLinksURL = backendUrl + "extension/manage-links/delete-links";
export const deleteInstallUninstallLinksURL = backendUrl + "extension/manage-links/delete-install-uninstall-link";


//   Partner Url
export const getPartnerExtension = backendUrl + "getPartnerExtension";
export const partnerExtensionList = backendUrl + "partner/my-extension";
export const addEndUser = backendUrl + "end-user/create";
export const suspendEndUser = backendUrl + "partner/suspend-extesnion-user";
export const unSuspendEndUser =
  backendUrl + "partner/un-suspend-extesnion-user";
export const deleteEndUser = backendUrl + "partner/delete-extesnion-user";
export const activateEndUser = backendUrl + "partner/activate-extesnion-user";
export const getEndUser = backendUrl + "ceo/fetch-all-end-users";

// Extension urls common for all user type
export const extensionInfo = backendUrl + "extension/details";
export const connectedPartnerList = backendUrl + "extension/partner-list";
export const connectedExtUserList =
  backendUrl + "extension/extension-user-list";

// End User url

export const addCSVForUser = backendUrl + "end-user/addcsvEndUser";

export const fetchFileAPI = backendUrl + "get-file-from-server/";

// Stripe URLs

export const fetchStripeKeysList = backendUrl + "stripe/fetch-stripe-api-keys"; // GET
export const deleteStripeKeys = backendUrl + "stripe/delete-stripe-key/"; // DELETE
export const deleteStripeAutomation = backendUrl + "stripe/delete-stripe-automation/"; // DELETE
export const addOrUpdateStripeKeys = backendUrl + "stripe/add-update-stripe-keys"; // POST
export const fetchStripeAutomation = backendUrl + "stripe/fetch-all-automation"; // GET
export const fetchAllStripeProductList = backendUrl + "stripe/fetch-all-stripe-products"; // POST
export const fetchAllStripePlanList = backendUrl + "stripe/fetch-all-stripe-plans"; // POST
export const addOrUpdateStripeAutomation = backendUrl + "stripe/add-update-stripe-automation";