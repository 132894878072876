import React, { useEffect, useState } from "react";
import "../../assets/css/FallbackLoader.css"

const SuspenseLoadingFallback = () => {
  const [updatedText, setUpdatedText] = useState("Loading Kyubi...");

  useEffect(() => {
    setTimeout(() => {
      setUpdatedText("Do you know Kyubi named after an anime named `Naruto`");
    }, 25 * 1000);
  }, []);

  return (
    <div className="">
      <div className="big-loader-gen row">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.094"
          height="12.094"
          viewBox="0 0 12.094 12.094"
          className="loader-ico"
        >
          <g transform="translate(-2 -2)">
            <g transform="translate(2 2)">
              <path
                className="a"
                d="M17.628,12H15.209A1.213,1.213,0,0,0,14,13.209v3.628a1.213,1.213,0,0,0,1.209,1.209h2.419a1.213,1.213,0,0,0,1.209-1.209V13.209A1.213,1.213,0,0,0,17.628,12Z"
                transform="translate(-6.744 -5.953)"
              />
              <path
                className="b"
                d="M5.628,2H3.209A1.213,1.213,0,0,0,2,3.209V6.838A1.213,1.213,0,0,0,3.209,8.047H5.628A1.213,1.213,0,0,0,6.838,6.838V3.209A1.213,1.213,0,0,0,5.628,2Z"
                transform="translate(-2 -2)"
              />
              <path
                className="c"
                d="M17.628,2H15.209A1.213,1.213,0,0,0,14,3.209V4.419a1.213,1.213,0,0,0,1.209,1.209h2.419a1.213,1.213,0,0,0,1.209-1.209V3.209A1.213,1.213,0,0,0,17.628,2Z"
                transform="translate(-6.744 -2)"
              />
              <path
                className="d"
                d="M5.628,16H3.209A1.213,1.213,0,0,0,2,17.209v1.209a1.213,1.213,0,0,0,1.209,1.209H5.628a1.213,1.213,0,0,0,1.209-1.209V17.209A1.213,1.213,0,0,0,5.628,16Z"
                transform="translate(-2 -7.534)"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className="suspense-load-text">
        <span>{updatedText}</span>
      </div>
    </div>
  );
};

export default SuspenseLoadingFallback;
