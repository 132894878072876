import React from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import browserHistory from "../../history";
import { ToastContainer, toast } from "react-toastify";
import { Login as userLogin } from "../../Services/Auth/Authenticate";
import { env } from "../../Config/Const";
import { appEnv } from "../../Config/env";
import {
  loginError,
  invalidEmail,
  noEmailEntered,
  noPasswordEntered,
} from "../../Messages/error";
import {
  facebook,
  facebookJon,
  messenger,
  tier5Website,
} from "../../Config/Url";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    document.title = "Login";
  }

  handleLoginChange = (event) => {
    this.setState({ [event.target?.name]: event.target.value });
  };

  handleValidation = () => {
    let validationMessage = "";
    if (this.state.email.length < 1) {
      validationMessage = noEmailEntered;
    } else {
      let validateEmail = new RegExp(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
      ).test(this.state.email);
      if (!validateEmail) {
        validationMessage = invalidEmail;
      }
    }
    if (!this.state.password.length) {
      validationMessage = noPasswordEntered;
    }
    return validationMessage;
  };

  handleLogin = (event) => {
    event.preventDefault();
    let validate = this.handleValidation();
    if (validate.length) {
      toast.error(validate);
      return;
    }
    userLogin({ email: this.state.email, password: this.state.password })
      .then((res) => {
        if (appEnv != env.live) {
          console.log("login response  :::::", res);
        }
        browserHistory.push("/dashboard");
      })
      .catch((err) => {
        if (appEnv != env.live) {
          console.log("login error :::", err);
        }
        toast.error(
          err.message && typeof err.message == "string"
            ? err.message
            : loginError
        );
      });
  };

  render() {
    return (
      <div className="main bgMain">
        <ToastContainer />
        <div className="logScreen d-flex">
          <div className="leftLog flex-1 w-50">
            <div className="leftInner h-100">
              <header className="header headerLog">
                <Link className="logo" to="/dashboard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="98"
                    height="29.485"
                    viewBox="0 0 98 29.485"
                  >
                    <g transform="translate(-52 -39.515)">
                      <text className="a" transform="translate(93 63)">
                        <tspan x="0" y="0">
                          Kyubi
                        </tspan>
                      </text>
                      <g transform="translate(-403 -140)">
                        <rect
                          className="b"
                          width="12"
                          height="12"
                          rx="3"
                          transform="translate(455 182)"
                        />
                        <rect
                          className="c"
                          width="12"
                          height="12"
                          rx="3"
                          transform="translate(455 197)"
                        />
                        <rect
                          className="d"
                          width="12"
                          height="12"
                          rx="3"
                          transform="translate(476 179.515) rotate(45)"
                        />
                        <rect
                          className="e"
                          width="12"
                          height="12"
                          rx="3"
                          transform="translate(470 197)"
                        />
                      </g>
                      <path
                        className="f"
                        d="M13.574,20.434H14.86V17.86h2.574V16.574H14.86V14H13.574v2.574H11V17.86h2.574Z"
                        transform="translate(59 30.649)"
                      />
                    </g>
                  </svg>
                </Link>
              </header>
              <div className="form logForm">
                <div className="headerSection">
                  <h2>
                    Login
                    <span className="inlineFig">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.132"
                        height="5.836"
                        viewBox="0 0 13.132 5.836"
                      >
                        <path
                          d="M13.132,514.848a.249.249,0,0,1-.076.182l-2.918,2.69a.237.237,0,0,1-.266.046.229.229,0,0,1-.144-.22v-1.7H.243A.234.234,0,0,1,0,515.6v-1.459a.234.234,0,0,1,.243-.243H9.727v-1.7a.22.22,0,0,1,.144-.22.244.244,0,0,1,.266.038l2.918,2.66A.24.24,0,0,1,13.132,514.848Z"
                          transform="translate(0 -511.952)"
                        />
                      </svg>
                    </span>
                  </h2>
                </div>
                <div className="formParent">
                  <form
                    onSubmit={this.handleLogin}
                    id="form_100409"
                    className="form-horizontal"
                  >
                    <div className="form-group">
                      <label className="control-label" for="element_1">
                        Email
                      </label>
                      <div className="form_inner">
                        <input
                          id="email"
                          name="email"
                          className="form-control form-bg"
                          type="email"
                          maxLength="255"
                          onChange={this.handleLoginChange}
                        />
                        <span className="bgForm">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="16.5"
                            viewBox="0 0 22 16.5"
                          >
                            <g className="a" transform="translate(0 -3)">
                              <path
                                d="M18.333,3H3.667A3.677,3.677,0,0,0,0,6.667v9.167A3.677,3.677,0,0,0,3.667,19.5H18.333A3.677,3.677,0,0,0,22,15.833V6.667A3.677,3.677,0,0,0,18.333,3ZM19.8,8.317l-7.242,4.858A3.09,3.09,0,0,1,11,13.633a3.09,3.09,0,0,1-1.558-.458L2.2,8.317a.97.97,0,0,1-.183-1.283A.97.97,0,0,1,3.3,6.85l7.242,4.858a.993.993,0,0,0,1.008,0L18.792,6.85a.9.9,0,0,1,1.283.275A1,1,0,0,1,19.8,8.317Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        <span className="error_msg"></span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label" for="element_2">
                        Password
                      </label>
                      <div className="form_inner">
                        <input
                          id="password"
                          name="password"
                          className="form-control form-bg"
                          type="password"
                          maxLength="255"
                          onChange={this.handleLoginChange}
                        />
                        <span className="bgForm">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18.332"
                            height="22"
                            viewBox="0 0 18.332 22"
                          >
                            <g className="a" transform="translate(-2 0.002)">
                              <path
                                d="M18.5,9.166H7.5V6.6a3.789,3.789,0,0,1,3.3-3.85,3.714,3.714,0,0,1,4.033,3.666h0a.866.866,0,0,0,.917.917h.917a.866.866,0,0,0,.917-.917h0A6.4,6.4,0,0,0,11.349,0a6.475,6.475,0,0,0-6.6,6.416v2.75H3.833A1.839,1.839,0,0,0,2,11v6.416A4.538,4.538,0,0,0,6.583,22h9.166a4.538,4.538,0,0,0,4.583-4.583V11A1.839,1.839,0,0,0,18.5,9.166Zm-6.416,7.058v1.192a.986.986,0,0,1-.917.917.986.986,0,0,1-.917-.917V16.223a1.774,1.774,0,0,1-.917-1.558,1.833,1.833,0,1,1,3.666,0A1.774,1.774,0,0,1,12.082,16.223Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        <span className="error_msg"></span>
                      </div>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue btn-large">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
                <div className="forgotPass">
                  <p>
                    Forgot your password? <Link to="/forgot">Click here</Link>{" "}
                    to recover now.
                  </p>
                </div>
              </div>
              <footer className="footer footerLog d-flex f-align-center">
                <p>
                  Powered by{" "}
                  <Link to={facebookJon} target="_blank">
                    Jon Vaughn
                  </Link>{" "}
                  and{" "}
                  <Link to={tier5Website} target="_blank">
                    Tier5
                  </Link>
                </p>
                <div className="socialFt ml-a">
                  <Link className="socFB" to={facebook} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                    >
                      <path
                        className="a"
                        d="M22,3.667A3.794,3.794,0,0,0,18.333,0H3.667A3.794,3.794,0,0,0,0,3.667V18.333A3.794,3.794,0,0,0,3.667,22H11V13.689H8.311V10.022H11V8.6c0-2.469,1.858-4.693,4.131-4.693h2.958V7.578H15.131a.9.9,0,0,0-.709.978v1.467h3.667v3.667H14.422V22h3.911A3.794,3.794,0,0,0,22,18.333Z"
                      />
                    </svg>
                  </Link>
                  <Link className="socMSG" to={messenger} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                    >
                      <path
                        className="a"
                        d="M21.239,24H4.749A2.684,2.684,0,0,1,2,21.263V4.749A2.685,2.685,0,0,1,4.749,2h16.5A2.685,2.685,0,0,1,24,4.749v16.5A2.765,2.765,0,0,1,21.239,24ZM11.812,9.644,5.433,16.353l5.782-3.142,3.017,3.142,6.332-6.709-5.735,3.142Z"
                        transform="translate(-1.998 -1.998)"
                      />
                    </svg>
                  </Link>
                </div>
              </footer>
            </div>
          </div>
          <div className="rightLog d-flex f-align-center f-justify-center flex-1 w-50">
            <div className="logFig">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="396.083"
                height="296"
                viewBox="0 0 396.083 296"
              >
                <g transform="translate(-1286 -266)">
                  <g transform="translate(1269.9 228.7)">
                    <path
                      className="a"
                      d="M32.058,41.5a10.858,10.858,0,1,0,0,21.716,10.858,10.858,0,1,0,0-21.716Zm0,17.231a6.373,6.373,0,1,1,6.373-6.373A6.347,6.347,0,0,1,32.058,58.731Z"
                      transform="translate(6.938 5.714)"
                    />
                    <path
                      className="a"
                      d="M43.258,41.5A10.858,10.858,0,1,0,54.116,52.358,10.983,10.983,0,0,0,43.258,41.5Zm0,17.231a6.373,6.373,0,1,1,6.373-6.373A6.667,6.667,0,0,1,43.258,58.731Z"
                      transform="translate(22.175 5.714)"
                    />
                    <path
                      className="a"
                      d="M54.358,41.5a10.858,10.858,0,1,0,0,21.716A10.983,10.983,0,0,0,65.216,52.358,10.682,10.682,0,0,0,54.358,41.5Zm0,17.231a6.373,6.373,0,1,1,6.373-6.373A6.347,6.347,0,0,1,54.358,58.731Z"
                      transform="translate(37.276 5.714)"
                    />
                    <path
                      className="a"
                      d="M411.947,174.914V37.3H16.1v296H411.947V177.038c0-.236.236-.708.236-.944A10.223,10.223,0,0,1,411.947,174.914Zm-4.485,153.665H20.585V81.676H407.226v246.9Zm0-251.624H20.585V42.021H407.226V76.955Z"
                      transform="translate(0 0)"
                    />
                    <g transform="translate(114.059 55.711)">
                      <path
                        className="a"
                        d="M181.287,66.344H176.33v-3.3c0-10.622-2.36-12.982-12.982-12.982H82.621c-20.064,0-20.064,9.914-20.064,12.982v3.3H57.6v-3.3a15.889,15.889,0,0,1,8.026-14.4Q72,45.1,82.621,45.1h80.727c13.455,0,17.939,4.485,17.939,17.939v3.3Z"
                        transform="translate(-57.6 -45.1)"
                      />
                    </g>
                    <g transform="translate(20.585 125.581)">
                      <rect className="a" width="386.641" height="4.957" />
                    </g>
                    <g transform="translate(114.059 92.771)">
                      <path
                        className="a"
                        d="M297.657,84.4H57.6V60.8H297.657V84.4Zm-235.1-4.957H292.7V65.757H62.557Z"
                        transform="translate(-57.6 -60.8)"
                      />
                    </g>
                    <path
                      className="a"
                      d="M32.222,67.081l4.013-4.013L33.166,60,21.6,71.33,33.166,82.9l3.069-4.249-3.777-4.485H48.273V67.081Z"
                      transform="translate(7.482 30.882)"
                    />
                    <path
                      className="a"
                      d="M54.043,60l-3.069,3.069,3.777,4.013H38.7v7.081H54.751l-3.777,4.485,3.069,3.777,11.33-11.33Z"
                      transform="translate(30.746 30.882)"
                    />
                    <g transform="translate(46.314 219.054)">
                      <path
                        className="a"
                        d="M28.9,170.007h93.238V114.3H28.9Zm4.957-50.986h83.56v46.5H33.857Z"
                        transform="translate(-28.9 -114.3)"
                      />
                    </g>
                    <g transform="translate(167.641 219.054)">
                      <path
                        className="a"
                        d="M80.3,170.007h93.238V114.3H80.3Zm4.485-50.986h84.032v46.5H84.785Z"
                        transform="translate(-80.3 -114.3)"
                      />
                    </g>
                    <g transform="translate(286.135 219.054)">
                      <path
                        className="a"
                        d="M130.736,170.007h93V114.3H130.5v55.707Zm4.485-50.986h84.032v46.5H135.221Z"
                        transform="translate(-130.5 -114.3)"
                      />
                    </g>
                    <g transform="translate(90.454 167.597)">
                      <path
                        className="a svgTitle"
                        d="M288.129,116.1H47.6V92.5H288.129ZM52.557,111.148H283.173V97.457H52.557Z"
                        transform="translate(-47.6 -92.5)"
                      />
                    </g>
                  </g>
                </g>
              </svg>

              <div className="chromeExtNotif d-flex f-align-center f-justify-center">
                <span className="add">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                  >
                    <text className="a" transform="translate(6 12)">
                      <tspan x="-5.68" y="0">
                        +
                      </tspan>
                    </text>
                  </svg>
                </span>
                <span className="outerAdd"></span>
              </div>
            </div>

            <div className="logC logC-1 redBg"></div>
            <div className="logC logC-2 blueBg"></div>
            <div className="logC logC-3 redBg"></div>
            <div className="logC logC-4 yellowBg"></div>
            <div className="logC logC-5 blueBg"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
