import React from "react";
import ReactDOM from "react-dom";
// import "./Public/index.css";
import App from "./App";

const svg = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="98"
  height="29.485"
  viewBox="0 0 98 29.485"
>
  <style type="text/css">
    .b {
      fill: #f94024;
    }

    .c {
      fill: #67d859;
    }

    .d {
      fill: #419af4;
      background-color: #fff;
    }
    .e {
      fill: #f9e522;
    }
  </style>
  <g transform="translate(-52 -39.515)">
    <g transform="translate(-403 -140)">
      <rect
        class="b"
        width="12"
        height="12"
        rx="3"
        transform="translate(455 182)"
      />
      <rect
        class="c"
        width="12"
        height="12"
        rx="3"
        transform="translate(455 197)"
      />
      <rect
        class="d"
        width="12"
        height="12"
        rx="3"
        transform="translate(476 179.515) rotate(45)"
      />
      <rect
        class="e"
        width="12"
        height="12"
        rx="3"
        transform="translate(470 197)"
      />
    </g>
    <path
      class="f"
      d="M13.574,20.434H14.86V17.86h2.574V16.574H14.86V14H13.574v2.574H11V17.86h2.574Z"
      transform="translate(59 30.649)"
    />
  </g>
</svg>
`;
const svgDataUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
console.log(
  `%c \t\t\t\tKyubi - ${new Date().getFullYear()}`,
  `
  background-image: url(${svgDataUrl});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  color:#419af4;
  font-size:69px;
  font-family:Segoe UI;
  padding-bottom : 10px;
`
);

ReactDOM.render(<App />, document.getElementById("root"));
