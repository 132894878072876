import axios from "axios";
import errorMessage from "../../Messages/error";
import { jwt as jwtConst } from "../../Config/Const";
import { checkAuthUserType } from "../../Helper/authUserDetail";
import UserType from "../../Config/Const";
import { appEnv } from "../../Config/env";
import { env } from "../../Config/Const";
import { getCeoBalance, checkForUnlimitedEndUsers } from "../../Config/Url";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  authorization: "",
};

export const getMyBalance = () => {
  headers.authorization = jwtConst + localStorage.getItem("_kyubi_token");
  return new Promise((resolve, reject) => {
    axios
      .get(getCeoBalance, { headers: headers })
      .then((result) => {
        if (appEnv != env.live) {
          console.log("result in ceo self service  ::::", result);
        }
        resolve(result);
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          if (appEnv != env.live) {
            console.log("error in ceo self service  ::::", error.response);
          }
          reject(error.response.data);
        } else {
          if (appEnv != env.live) {
            console.log(
              "error in ceo self service #2 ::::",
              errorMessage.connectionError
            );
          }
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const checkForUnlimited = () => {
  headers.authorization = jwtConst + localStorage.getItem("_kyubi_token");
  return new Promise((resolve, reject) => {
    axios
      .get(checkForUnlimitedEndUsers, { headers: headers })
      .then((result) => {
        if (appEnv != env.live) {
          console.log("result in ceo self service  ::::", result);
        }
        resolve(result);
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          if (appEnv != env.live) {
            console.log("error in ceo self service  ::::", error.response);
          }
          reject(error.response.data);
        } else {
          if (appEnv != env.live) {
            console.log(
              "error in ceo self service #2 ::::",
              errorMessage.connectionError
            );
          }
          reject(errorMessage.connectionError);
        }
      });
  });
};