import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import { ProtectedRoute, UnProtectedRoute } from "./CustomRouteMiddleware";
import { checkAuthUserType } from "./Helper/authUserDetail";
import UserType from "./Config/Const";
import history from "./history";

// const Loader = lazy(() => import("./components/Util/loader"))``
import Loader from "./components/Util/SuspenseFallbackLoader";

const APIRoutes = lazy(() => import("./apiRoutes"));
const Home = lazy(() => import("./components/Home"));
const Details = lazy(() => import("./common/Details"));
const Login = lazy(() => import("./components/Login/Login"));
//const Footer = lazy(() => import("./components/Footer/Footer"));
const Footer = lazy(() => import("./components/Footer/FooterAPI"));
const PageNotFound = lazy(() => import("./common/PageNotFound"));
const ForgetPassword = lazy(() => import("./components/forgot/forget"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const UploadCSV = lazy(() => import("./components/Util/Modal/UploadCSV"));
const CeoList = lazy(() => import("./components/SuperAdmin/CEO/List/List"));
const PartnerList = lazy(() => import("./components/Ceo/Partners/List/List"));
const CeoEndUserList = lazy(() => import("./components/Ceo/EndUser/EndUser"));
const DashboardAdsList = lazy(() => import("./components/SuperAdmin/Ads/List"));
const UsersList = lazy(() =>
  import("./components/Partners/EndUsers/List/List")
);
const CreateCeo = lazy(() =>
  import("./components/SuperAdmin/CEO/Create/Create")
);
const CeoExtensionList = lazy(() =>
  import("./components/Ceo/Extension/List/List")
);
const AllUserList = lazy(() =>
  import("./components/SuperAdmin/EndUser/List/List")
);
const CreatePartner = lazy(() =>
  import("./components/Ceo/Partners/Create/CreateRE")
);
// const EndUserReactTable = lazy(() =>
//   import("./components/SuperAdmin/EndUser/List")
// );
const ResetPassword = lazy(() =>
  import("./components/ResetPassword/ResetPassword")
);
const CreateUsers = lazy(() =>
  import("./components/Partners/EndUsers/Create/Create")
);
const CreateExtension = lazy(() =>
  import("./components/Ceo/Extension/Create/Create")
);
const UpdatePassword = lazy(() =>
  import("./components/updatePassword/UpdatePassword")
);
const AllPartnerList = lazy(() =>
  import("./components/SuperAdmin/Partners/List/List")
);
const StripeDetails = lazy(() =>
  import("./components/Stripe/KeyDetails/StripeDetails")
);
const CeoExtensionDetails = lazy(() =>
  import("./components/Ceo/Extension/Details/Details")
);
const PartnerExtensionList = lazy(() =>
  import("./components/Partners/Extension/List/List")
);
const ConfigureWhitelabel = lazy(() =>
  import("./components/SuperAdmin/configureWhitelabel")
);
const TrainingVideos = lazy(() =>
  import("./components/Common/TrainingVideos/TrainingVideos")
);
const PartnerExtensionDetails = lazy(() =>
  import("./components/Ceo/Partners/Details/Details")
);
const AutomationDetails = lazy(() =>
  import("./components/Stripe/Automations/AutomationDetails")
);
const WhiteLabelConfigListSu = lazy(() =>
  import("./components/SuperAdmin/WhitelabelConfigList")
);
const WhiteLabelRequestList = lazy(() =>
  import("./components/SuperAdmin/WhiteLabelRequestList")
);
const WhitelabelConfigList = lazy(() =>
  import("./components/Ceo/WhitelabelExtension/WhitelabelConfigList")
);
const WhiteLabelConfigurationList = lazy(() =>
  import("./components/Ceo/Extension/List/WhiteLabelConfigurationList")
);



const routing = () => (
  <div style={{ backgroundColor: "#f8f8f8" }}>
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* <UnProtectedRoute exact path="/" component={Home} label="Home" /> */}
        <UnProtectedRoute
          exact
          path="/"
          component={() => <Redirect to="login" />}
        />

        <UnProtectedRoute path="/login" component={Login} />
        <UnProtectedRoute exact path="/forgot" component={ForgetPassword} />
        {/* <UnProtectedRoute exact path="/forgot/:extId" component={ForgetPassword} /> */}

        <Route
          exact
          path="/reset-password/:type/:token"
          component={ResetPassword}
        />
        {/* <UnProtectedRoute
      exact
      path="/reset-password/end-user/:token"
      component={ResetPassword}
    /> */}
        <ProtectedRoute
          path="/create-ceo"
          component={() =>
            checkAuthUserType(UserType.superAdmin) ? (
              <CreateCeo />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/ceo-list"
          component={() =>
            checkAuthUserType(UserType.superAdmin) ? (
              <CeoList />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/update-password"
          component={() => <UpdatePassword />}
        />
        <ProtectedRoute
          path="/extension-list"
          component={() =>
            checkAuthUserType(UserType.ceo) ||
            checkAuthUserType(UserType.superAdmin) ? (
              <CeoExtensionList />
            ) : (
              <PartnerExtensionList />
            )
          }
        />
        <ProtectedRoute
          path="/whitelabel-configurations"
          component={() =>
            checkAuthUserType(UserType.ceo) ? (
              <WhitelabelConfigList />
            ) : (
              <PageNotFound />
            )
          }
        />

        <ProtectedRoute
          path="/whitelabel-configs"
          component={() =>
            checkAuthUserType(UserType.superAdmin) ? (
              <WhiteLabelConfigListSu />
            ) : checkAuthUserType(UserType.ceo) ? (
              <WhitelabelConfigList />
            ) : (
              <PageNotFound />
            )
          }
        />

        <ProtectedRoute
          path="/create-partner"
          component={() =>
            checkAuthUserType(UserType.ceo) ? (
              <CreatePartner />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/partner-list"
          component={() =>
            checkAuthUserType(UserType.ceo) ? (
              <PartnerList />
            ) : checkAuthUserType(UserType.superAdmin) ? (
              <AllPartnerList />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/create-extension"
          component={() =>
            checkAuthUserType(UserType.ceo) ? (
              <CreateExtension />
            ) : (
              <PageNotFound />
            )
          }
        />
        {/* <ProtectedRoute
      path="/extension-details"
      component={PartnerExtensionDetails}
    /> */}
        {/* <ProtectedRoute
      path="/partner-details"
      component={() =>
        checkAuthUserType(UserType.ceo) ? (
          <PartnerExtensionDetails />
        ) : (
          <Error404 />
        )
      }
    /> */}
        <ProtectedRoute
          path="/create-user"
          component={() =>
            checkAuthUserType(UserType.partner) ||
            checkAuthUserType(UserType.ceo) ? (
              <CreateUsers />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/user-list"
          component={() =>
            checkAuthUserType(UserType.superAdmin) ? (
              <AllUserList />
            ) : checkAuthUserType(UserType.ceo) ? (
              <CeoEndUserList />
            ) : (
              <UsersList />
            )
          }
        />
        <ProtectedRoute
          path="/stripe-details"
          component={() =>
            checkAuthUserType(UserType.ceo) ||
            checkAuthUserType(UserType.partner) ? (
              <StripeDetails />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/create-automation"
          component={() =>
            checkAuthUserType(UserType.ceo) ||
            checkAuthUserType(UserType.partner) ? (
              <AutomationDetails />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/whitelabel-requests"
          component={() =>
            checkAuthUserType(UserType.superAdmin) ? (
              <WhiteLabelRequestList />
            ) : (
              <PageNotFound />
            )
          }
        />
        <ProtectedRoute
          path="/dashboard"
          component={() =>
            checkAuthUserType(UserType.ceo) ? (
              <CeoExtensionList />
            ) : (
              <Dashboard />
            )
          }
        />
        <ProtectedRoute
          path="/upload-csv/:type"
          component={(props) =>
            checkAuthUserType(UserType.user) ? (
              <PageNotFound />
            ) : (
              <UploadCSV {...props} />
            )
          }
        />
        <ProtectedRoute
          path="/configure-whitelabel"
          component={(props) =>
            checkAuthUserType(UserType.superAdmin) ||
            checkAuthUserType(UserType.ceo) ? (
              <ConfigureWhitelabel {...props} />
            ) : (
              <PageNotFound />
            )
          }
        />

        <ProtectedRoute
          exact
          path="/dashboard-ads"
          component={(props) =>
            checkAuthUserType(UserType.superAdmin) ? (
              <DashboardAdsList {...props} />
            ) : (
              <PageNotFound />
            )
          }
        />

        <ProtectedRoute
          exact
          path="/training-videos"
          component={(props) =>
            !checkAuthUserType(UserType.superAdmin) ? (
              <TrainingVideos {...props} />
            ) : (
              <PageNotFound />
            )
          }
        />

        <ProtectedRoute path="/details/:extId" component={Details} />
        <ProtectedRoute path="/api" component={APIRoutes} />
        <Route path="/error-404" component={PageNotFound} />
        <Route component={PageNotFound} />
      </Switch>
      {history.location.pathname != "/login" && <Footer />}
    </Suspense>
  </div>
);

export default routing;
 