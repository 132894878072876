import React, { Component } from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./App.css";
import { Router } from "react-router-dom";

import { appEnv } from "../src/Config/env";
import { env } from "../src/Config/Const";

import history from "./history";
import Routes from "./routes";

import { getMyBalance } from "./Services/Ceo/Myinfo";
import { checkAuthUserType } from "./Helper/authUserDetail";
import { ceo } from "./Config/Const";
class App extends Component {
  static defaultProps = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidUpdate() {
    window.addEventListener("resize", function () {
      let documentBody = document.querySelectorAll(".bodyLoggedIn");
      let documentHeader = document.querySelectorAll(".header");
      var getFooter = document.querySelectorAll(".footerCommon");

      if (
        documentBody[0].clientHeight + documentHeader[0].clientHeight + 60 <
        window.innerHeight
      ) {
        getFooter[0].setAttribute(
          "style",
          "position: fixed; left: 50%; transform: translateX(-50%); bottom: 0;"
        );
      } else {
        getFooter[0].setAttribute(
          "style",
          "position: relative; left: auto; transform: none; bottom: auto;"
        );
      }
    });
    this.positionFooter();
  }

  componentWillUnmount() {
    this.positionFooter();
  }

  positionFooter = (e) => {
    let documentBody = document.querySelectorAll(".bodyLoggedIn");
    var getFooter = document.querySelectorAll(".footerCommon");
    var getHeader = document.querySelectorAll(".header");

    setTimeout(() => {
      if (
        documentBody[0].clientHeight <
        window.innerHeight - (getFooter[0].clientHeight + 130)
      ) {
        getFooter[0].setAttribute(
          "style",
          "position: fixed; left: 50%; transform: translateX(-50%); bottom: 0;"
        );
      } else {
        getFooter[0].setAttribute(
          "style",
          "position: relative; left: auto; transform: none; bottom: auto;"
        );
      }
    }, 100);
  };

  componentDidMount() {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        localStorage.removeItem("kyubi_ads_data");
        localStorage.removeItem("_kyubi_su_stats");
        localStorage.removeItem("kyubi_balance_data");
        localStorage.removeItem("balance_api_running");
        localStorage.removeItem("check_for_balance_change");
      }
    }
    if (checkAuthUserType(ceo)) {
      let getAPIStatus = localStorage.getItem("balance_api_running");
      if (!getAPIStatus || getAPIStatus != 1) {
        localStorage.setItem("balance_api_running", 1);
        getMyBalance()
          .then((res) => {
            localStorage.setItem(
              "kyubi_balance_data",
              JSON.stringify(res.data.payload)
            );
            // console.log("settings data to new record");
          })
          .catch((err) => console.log("error", err))
          .finally(() => localStorage.removeItem("balance_api_running"));
      }
    }
  }
  render = () => {
    if (appEnv == env.live) {
      console.log = () => {};
    }
    return (
      <Provider>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    );
  };
}

export default App;
