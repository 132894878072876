import axios from "axios";
import jwt_decode from "jwt-decode";
import history from "../../history";
import {
  login as loginUrl,
  generateTokenEndUser as generateTokenEndUserUrl,
  generateToken as generateTokenUrl,
  resetPasswordEndUser as resetPasswordEndUserUrl,
  resetPassword as resetPasswordUrl,
} from "../../Config/Url";
import errorMessage from "../../Messages/error";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "",
};

export const Login = (loginDetails) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        loginUrl,
        {
          email: loginDetails.email,
          password: loginDetails.password,
        },
        { headers: headers }
      )
      .then((result) => {
        localStorage.setItem("_kyubi_token", result.data.token);
        resolve(true);
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const checkLogin = () => {
  const token = localStorage.getItem("_kyubi_token");
  if (!token) {
    return false;
  }
  try {
    /* Check for token validations */
    const jwtPayload = jwt_decode(token);
    if (jwtPayload.exp < new Date().getTime() / 1000) {
      localStorage.removeItem("_kyubi_token");
      localStorage.removeItem("kyubi_balance_data");
      localStorage.removeItem("check_for_balance_change");
      localStorage.removeItem("kyubi_ads_data");
      localStorage.removeItem("balance_api_running");
      localStorage.removeItem("_kyubi_su_stats");
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem("_kyubi_token");
  localStorage.removeItem("kyubi_balance_data");
  localStorage.removeItem("kyubi_ads_data");
  localStorage.removeItem("balance_api_running");
  localStorage.removeItem("check_for_balance_change");
  localStorage.removeItem("_kyubi_su_stats");
  history.push("/login");
  // window.location = "/login";
};

export const generateToken = (data) => {
  return new Promise((resolve, reject) => {
    let url = "";
    if (data.extId) url = generateTokenEndUserUrl;
    else url = generateTokenUrl;
    axios
      .post(url, data, { headers: headers })
      .then((result) => {
        resolve(true);
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};

export const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    let url = "";
    if (data.type == "user") url = resetPasswordUrl + data.token;
    else url = resetPasswordEndUserUrl + data.token;
    axios
      .post(url, data, { headers: headers })
      .then((result) => {
        resolve(true);
      })
      .catch((error) => {
        if (error != null && error.response != null) {
          reject(error.response.data);
        } else {
          reject(errorMessage.connectionError);
        }
      });
  });
};
